import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

const Faq = () => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const componentRef = useRef<any>(null);
  const [signed, setSigned] = useState(false);

  const { items }: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (items.status) {
      setSigned(true);
    }
  }, [items]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
        {/* <Header isSignIn={signed} /> */}

        <iframe
          src="https://umgcventures.kustomer.help"
          width={"100%"}
          className="h-[930px] lg:h-[1250px]"
          frameBorder="0"
        ></iframe>
      </div>
      <Footer />
    </Layout>
  );
};

export default Faq;
export { Head } from "../components/Layout/Head";
